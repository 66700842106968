import { useState } from "react";
import ReactSlider from "react-slider";

// Ad.js
export const Ad = () => {
  const [slider, setSlider] = useState<number>(0);

  return (
    <div className="flex justify-between gap-[15px] p-[15px] box-border">
      <div className="p-[60px] w-[60%] bg-[#fafafa] rounded-[12px] shadow">
        <h2 className="font-['Trenda_Bold'] tracking-[1px] text-[38px] sm:text-[48px] md:text-[62px] mb-[15px] leading-[1.2]">
          Reklam ver
        </h2>
        <div className="flex flex-col gap-[30px]">
          <div className="flex flex-col gap-[10px]">
            <h3 className="font-[500]">
              Reklamını yapacağınız markanın adını yazın
            </h3>
            <input
              className="border border-[#dddddd] rounded-[12px] focus:outline-none py-[8px] px-[16px] w-full"
              placeholder="Ör: Cronwork Akademi"
              type="text"
              name="search"
            />
          </div>
          <div className="flex justify-between gap-[30px]">
            <div className="w-full flex flex-col gap-[10px]">
              <h3 className="font-[500]">Reklamverenin adını yazın</h3>
              <input
                className="border border-[#dddddd] rounded-[12px] focus:outline-none py-[8px] px-[16px] w-full"
                placeholder="Ör: Doğu Gürdal"
                type="text"
                name="search"
              />
            </div>
            <div className="w-full flex flex-col gap-[10px]">
              <h3 className="font-[500]">Reklamverenin TC'sini yazın</h3>
              <input
                className="border border-[#dddddd] rounded-[12px] focus:outline-none py-[8px] px-[16px] w-full"
                placeholder="Ör: 1234567890"
                type="text"
                name="search"
              />
            </div>
          </div>

          <div className="flex flex-col gap-[10px] mb-[15px]">
            <h3 className="font-[500] flex justify-between">
              <span>Reklamını bütçenizi belirleyin</span>
              <strong>{slider} TL</strong>
            </h3>
            <ReactSlider
              onChange={(v: number) => setSlider(v * 10)}
              ariaLabelledby="slider-label"
              className="horizontal-slider"
              thumbClassName="example-thumb"
              trackClassName="example-track"
              renderThumb={(props, state) => <div {...props}></div>}
            />
          </div>

          <div className="flex flex-col gap-[10px]">
            <h3 className="font-[500]">Yayınlamak istediğiniz mecrayı seçin</h3>
            <select
              className="border border-[#dddddd] rounded-[12px] focus:outline-none py-[10px] px-[16px] w-full"
              name="search"
            >
              <option value="">Seçin</option>
              <optgroup>
                <option disabled>Alışveriş Merkezleri</option>
                <option value="">Korupark</option>
                <option value="">Zafer Plaza</option>
                <option value="">Anatolium</option>
                <option value="">Down Town</option>
              </optgroup>

              <optgroup>
                <option disabled>Marketler</option>
                <option value="">Özhanlar</option>
                <option value="">Seyhanlar</option>
                <option value="">Onur Market</option>
              </optgroup>
            </select>
          </div>

          <div className="flex flex-col gap-[10px]">
            <h3 className="font-[500]">Yayınlanacak günleri seçin</h3>
            <div className="flex justify-between gap-[10px]">
              <label className="w-full justify-center cursor-pointer border border-[#eeeeee] px-[8px] py-[4px] flex items-center gap-[5px] text-[12px] rounded-[12px]">
                <input type="checkbox" className="default:ring-2" /> Pazartesi
              </label>
              <label className="w-full justify-center cursor-pointer border border-[#eeeeee] px-[8px] py-[4px] flex items-center gap-[5px] text-[12px] rounded-[12px]">
                <input type="checkbox" className="default:ring-2" /> Salı
              </label>
              <label className="w-full justify-center cursor-pointer border border-[#eeeeee] px-[8px] py-[4px] flex items-center gap-[5px] text-[12px] rounded-[12px]">
                <input type="checkbox" className="default:ring-2" /> Çarşamba
              </label>
              <label className="w-full justify-center cursor-pointer border border-[#eeeeee] px-[8px] py-[4px] flex items-center gap-[5px] text-[12px] rounded-[12px]">
                <input type="checkbox" className="default:ring-2" /> Perşembe
              </label>
              <label className="w-full justify-center cursor-pointer border border-[#eeeeee] px-[8px] py-[4px] flex items-center gap-[5px] text-[12px] rounded-[12px]">
                <input type="checkbox" className="default:ring-2" /> Cuma
              </label>
              <label className="w-full justify-center cursor-pointer border border-[#eeeeee] px-[8px] py-[4px] flex items-center gap-[5px] text-[12px] rounded-[12px]">
                <input type="checkbox" className="default:ring-2" /> Cumartesi
              </label>
              <label className="w-full justify-center cursor-pointer border border-[#eeeeee] px-[8px] py-[4px] flex items-center gap-[5px] text-[12px] rounded-[12px]">
                <input type="checkbox" className="default:ring-2" /> Pazar
              </label>
            </div>
          </div>

          <div className="w-full flex flex-col gap-[10px]">
            <h3 className="font-[500]">
              Yayınlamak için reklam görselini seçin
            </h3>
            <label className="block">
              <span className="sr-only">Seçin</span>
              <input
                type="file"
                className="block w-full text-sm text-slate-500
      file:mr-4 file:py-2 file:px-4
      file:rounded-full file:border-0
      file:text-sm file:font-semibold
      file:bg-violet-50 file:text-violet-700
      hover:file:bg-violet-100
    "
              />
            </label>
          </div>

          <input
            type="submit"
            className="block w-full text-sm text-[#ffffff]
      mr-4 py-2 px-4
      rounded-full border-0
      text-sm font-semibold
      bg-[#333333] text-violet-700
      hover:bg-[#000000]
      cursor-pointer
    "
            value="Reklamı Yayına Al"
          />
        </div>
      </div>
      <div className="p-[60px] w-full bg-[#fafafa] rounded-[12px] shadow justify-center flex">
        <img src="/kiosk.png" className="" alt="" />
      </div>
    </div>
  );
};
