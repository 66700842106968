import { useEffect } from "react";
import { useMetaTags } from "react-metatags-hook";
import { Link } from "react-router-dom";

// Contact.js
export const Contact = () => {
  useEffect(() => {
    if (document.getElementsByClassName("mainContainer").length > 0)
      document.getElementsByClassName(
        "mainContainer"
      )[0].className = `mainContainer bg-mint bg-opacity-60`;
  }, []);

  useMetaTags(
    {
      title: "İletişim - Cronwork Dijital Ajans",
      description:
        "Firmalar için yazılım geliştirme desteği sunuyoruz. Blockchain, web app, crm ve bayi paneli çözümleri.",
    },
    []
  );

  return (
    <div className="my-[100px]">
      <h2 className="font-['Trenda_Bold'] tracking-[1px] text-[38px] sm:text-[48px] md:text-[62px] mb-[15px] leading-[1.2]">
        iletişim
      </h2>
      <ul className="w-full font-['Trenda_Semibold'] text-[22px] md:text-[22px] lg:text-[24px] leading-[1.6] tracking-[2px] flex flex-col">
        <li className="cursor-default hover:opacity-[1] opacity-[0.5]">
          <div className="flex flex-col sm:!flex-row gap-[5px] items-start sm:!items-center justify-between transition-all mb-[10px] sm:!mb-0">
            <span>eposta</span>
            <u className="bg-mint bg-opacity-60">
              <Link to="mailto:root@cronwork.com">root [at] cronwork.com</Link>
            </u>
          </div>
        </li>
        <li className="cursor-default hover:opacity-[1] opacity-[0.5]">
          <div className="flex flex-col sm:!flex-row gap-[5px] items-start sm:!items-center justify-between transition-all mb-[10px] sm:!mb-0">
            <span>telefon</span>
            <u className="bg-mint bg-opacity-60">
              <Link to="tel:05336389094">0090 533 638 9094</Link>
            </u>
          </div>
        </li>
        <li className="cursor-default hover:opacity-[1] opacity-[0.5]">
          <div className="flex flex-col sm:!flex-row gap-[5px] items-start sm:!items-center justify-between transition-all mb-[10px] sm:!mb-0">
            instagram
            <u className="bg-mint bg-opacity-60">
              <Link to="https://instagram.com/cronwork_" target="_blank">
                @cronwork_
              </Link>
            </u>
          </div>
        </li>
      </ul>
    </div>
  );
};
