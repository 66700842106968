// App.js
import { HashRouter, Route, Routes } from "react-router-dom";
import { Layout } from "./components/Layout";
import { Home } from "./components/Home";
import { About } from "./components/About";
import { Contact } from "./components/Contact";
import { OurTeam } from "./components/OurTeam";
import { Ad } from "./components/Ad";
import { Page } from "./components/Page";
import { NotFound } from "./components/NotFound";

function App() {
  return (
    <HashRouter basename="/">
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/hakkimizda" element={<About />} />
          <Route path="/ekibimiz" element={<OurTeam />} />
          <Route path="/iletisim" element={<Contact />} />
          <Route
            path="/blockchain-cozumleri"
            element={<Page type="blockchain çözümleri" color="black" />}
          />
          <Route
            path="/internet-sitesi-ve-eticaret"
            element={<Page type="internet sitesi ve eticaret" color="brown" />}
          />
          <Route
            path="/ucuncu-parti-danismanlik"
            element={<Page type="üçüncü parti danışmanlık" color="blue" />}
          />
          <Route
            path="/kapali-devre-cozumler"
            element={<Page type="kapalı devre çözümler" color="green" />}
          />
          <Route
            path="/otomasyon-sistemleri"
            element={<Page type="otomasyon sistemleri" color="purple" />}
          />
        </Route>
        <Route path="/ad" element={<Ad />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
