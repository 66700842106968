import { Link } from "react-router-dom";
import { Button } from "./Button";

// Header.js
export const Header = () => {
  return (
    <header className="flex-grow">
      <div className="flex justify-between items-center">
        <h1 className="text-[32px] tracking-[1px]">
          <Link to="/">
            cronwork<span className="blink">_</span>
          </Link>
        </h1>
        <Button url="/iletisim" label="iletişim" />
      </div>
      {/* 
      <nav>
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/about">About</a>
          </li>
          <li>
            <a href="/contact">Contact</a>
          </li>
        </ul>
      </nav>
      */}
    </header>
  );
};
