import { Button } from "./Button";

// Footer.js
export const Footer = () => {
  return (
    <footer className="sm:mt-[30px] flex justify-evenly bg-white sm:!bg-transparent py-[15px] sm:!py-0 sm:!justify-end gap-[30px] z-[1] fixed bottom-0 left-0 w-full justify-center sm:relative">
      <Button url="/hakkimizda" label="hakkımızda" />
      <Button url="/ekibimiz" label="ekibimiz" />
    </footer>
  );
};
