import { Link } from "react-router-dom";

// Button.js
interface ButtonProps {
  label: string;
  url: string;
}

export const Button = (props: ButtonProps) => {
  return (
    <Link
      to={props.url}
      className="text-[18px] transition-all bg-white shadow-[-5px_5px_0_#000] hover:shadow-none text-black px-[12px] py-[7px] font-['Trenda_Semibold']"
    >
      {props.label}
    </Link>
  );
};
