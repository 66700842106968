// Layout.js
import { Outlet } from "react-router-dom";
import { Header } from "./Header";
import { Footer } from "./Footer";

export const Layout = () => {
  return (
    <div className="mainContainer bg-primary">
      <div className="text-white flex justify-between flex-col min-h-[100dvh] px-[30px] py-[80px] sm:px-[60px] sm:py-[60px] md:px-[100px] md:py-[40px] lg:px-[160px] lg:py-[80px]">
        <Header />
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};
