import { useEffect } from "react";
import { useMetaTags } from "react-metatags-hook";

// About.js
export const About = () => {
  useEffect(() => {
    if (document.getElementsByClassName("mainContainer").length > 0)
      document.getElementsByClassName(
        "mainContainer"
      )[0].className = `mainContainer bg-primary bg-opacity-60`;
  }, []);

  useMetaTags(
    {
      title: "Hakkımızda - Cronwork Dijital Ajans",
      description:
        "Firmalar için yazılım geliştirme desteği sunuyoruz. Blockchain, web app, crm ve bayi paneli çözümleri.",
    },
    []
  );

  return (
    <div className="my-[100px]">
      <h2 className="font-['Trenda_Bold'] tracking-[1px] text-[38px] sm:text-[48px] md:text-[62px] mb-[15px] leading-[1.2]">
        hakkımızda
      </h2>
      <div className="leading-[28px] text-justify text-[18px] sm:!text-[22px] flex flex-col gap-[30px]">
        <strong className="font-['Trenda_Semibold'] text-[22px] md:text-[28px] leading-[1.6]">
          biz kimiz?
        </strong>
        <p>
          <u className="bg-primary bg-opacity-60 font-bold">2012</u> yılından
          beri, alanlarında uzman kişilerden oluşan bir kadro ile, dünya
          genelinde şirketlere yazılım çözümleri sunan{" "}
          <u className="bg-primary bg-opacity-60 font-bold">cronwork</u> adını;
          cronjob olarak da bilinen cron'dan almıştır. Cron; komut satırı
          yardımcı programı olup, unix benzeri işletim sistemlerinde tekrarlayan
          görevleri zamanlamak için kullanılan bir planlayıcıdır.
        </p>
        <p>
          Ağırlıklı olarak web teknolojileri üzerine hizmet veriyoruz.
          <u className="bg-primary bg-opacity-60 font-bold">2020</u> yılından bu
          yana, çalışmalarımıza{" "}
          <u className="bg-primary bg-opacity-60 font-bold">blockchain</u>{" "}
          tabanlı çözümleri de ekleyerek, yeni projeler geliştirmeye devam
          ediyoruz.
        </p>
        <strong className="font-['Trenda_Semibold'] text-[22px] md:text-[28px] leading-[1.6]">
          neler yaptık?
        </strong>

        <ul className="leading-[32px] flex flex-col gap-[15px] list-[square] pl-[20px]">
          <li>
            <u className="bg-primary bg-opacity-60 font-bold">2012</u> yılında,
            pazara Türkiye'nin sayılı AWS teknoloji partner firmalarından birisi
            olarak, ilk global startupımız ile giriş yaptık.{" "}
          </li>
          <li>
            <u className="bg-primary bg-opacity-60 font-bold">2014</u> yılında
            ilk yurtdışı iş bağlantılarımızı{" "}
            <u className="bg-primary bg-opacity-60 font-bold">İsviçre</u> ile
            oluşturmaya başladık. Dijital reklam alanında bu hareketleri daha
            sonra <u className="bg-primary bg-opacity-60 font-bold">Almanya</u>{" "}
            takip etti.
          </li>
          <li>
            <u className="bg-primary bg-opacity-60 font-bold">2016</u> yılından
            itibaren, özellikle dijital reklam ajanslarını hedefleyerek, sektöre
            özel geliştirdiğimiz ürünler ile pek çok memnun müşteriye ulaşmayı
            başardık.
          </li>
          <li>
            <u className="bg-primary bg-opacity-60 font-bold">2018</u> yılında
            fabrika ve endüstriyel firmalara kapalı devre çözümler, bayi
            panelleri ve iç süreçleri kolaylaştıracak yazılımlar hazırlamaya
            başladık.
          </li>
          <li>
            <u className="bg-primary bg-opacity-60 font-bold">2020</u> yılından
            itibaren{" "}
            <u className="bg-primary bg-opacity-60 font-bold">Türkiye</u> ve{" "}
            <u className="bg-primary bg-opacity-60 font-bold">Hollanda</u> başta
            olmak üzere, çeşitli firmalara,{" "}
            <u className="bg-primary bg-opacity-60 font-bold">blockchain</u>{" "}
            tabanlı yazılım geliştirme desteği vermeye başladık.
          </li>
        </ul>
      </div>
    </div>
  );
};
