import { useEffect, useState } from "react";
import { useMetaTags } from "react-metatags-hook";
import { Link } from "react-router-dom";

// Home.js
export const Home = () => {
  const [selected, setSelected] = useState<string>("bg-primary");

  useEffect(() => {
    if (document.getElementsByClassName("mainContainer").length > 0)
      document.getElementsByClassName(
        "mainContainer"
      )[0].className = `mainContainer ${selected}${
        selected === "bg-primary" ? "" : " bg-opacity-60"
      }`;
  });

  useMetaTags(
    {
      title: "Cronwork - Dijital Yazılım Ajansı",
      description:
        "Firmalar için yazılım geliştirme desteği sunuyoruz. Blockchain, web app, crm ve bayi paneli çözümleri.",
    },
    []
  );

  return (
    <div className="pb-[60px] sm:!pb-0">
      <h2 className="font-['Trenda_Bold'] tracking-[1px] text-[34px] sm:text-[48px] md:text-[62px] mb-[15px] leading-[1.2]">
        yazılım geliştirme
        <br />
        ortağınız
      </h2>
      <ul className="font-['Trenda_Semibold'] text-[20px] md:text-[22px] lg:text-[24px] leading-[1.6] tracking-[2px] inline-flex flex-col">
        <li
          className="cursor-pointer hover:opacity-[1] opacity-[0.5]"
          onMouseEnter={() => setSelected("bg-black")}
          onMouseLeave={() => setSelected("bg-primary")}
        >
          <div className="flex gap-[5px] transition-all hover:gap-[10px]">
            <Link to="/blockchain-cozumleri">blockchain çözümleri</Link>
          </div>
        </li>
        <li
          className="cursor-pointer hover:opacity-[1] opacity-[0.5]"
          onMouseEnter={() => setSelected("bg-brown")}
          onMouseLeave={() => setSelected("bg-primary")}
        >
          <div className="flex gap-[5px] transition-all hover:gap-[10px]">
            <Link to="/internet-sitesi-ve-eticaret">
              internet sitesi ve eticaret
            </Link>
          </div>
        </li>
        <li
          className="cursor-pointer hover:opacity-[1] opacity-[0.5]"
          onMouseEnter={() => setSelected("bg-blue")}
          onMouseLeave={() => setSelected("bg-primary")}
        >
          <div className="flex gap-[5px] transition-all hover:gap-[10px]">
            <Link to="/ucuncu-parti-danismanlik">üçüncü parti danışmanlık</Link>
          </div>
        </li>
        <li
          className="cursor-pointer hover:opacity-[1] opacity-[0.5]"
          onMouseEnter={() => setSelected("bg-green")}
          onMouseLeave={() => setSelected("bg-primary")}
        >
          <div className="flex gap-[5px] transition-all hover:gap-[10px]">
            <Link to="/kapali-devre-cozumler">kapalı devre çözümler</Link>
          </div>
        </li>
        <li
          className="cursor-pointer hover:opacity-[1] opacity-[0.5]"
          onMouseEnter={() => setSelected("bg-purple")}
          onMouseLeave={() => setSelected("bg-primary")}
        >
          <div className="flex gap-[5px] transition-all hover:gap-[10px]">
            <Link to="/otomasyon-sistemleri">otomasyon sistemleri</Link>
          </div>
        </li>
      </ul>
    </div>
  );
};
